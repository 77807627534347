import React, { useEffect, useRef, useState } from 'react';
import { TextInput, Title, Group, Paper, Image } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { InfoCircle, PyqSearchLogo, SearchNormal, SendIcon } from '../../assets/images';
import InfoTips from './InfoTips';
import { CustomDivider } from '../../molecules/OR';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

const AutoScrollLists = () => {
  const navigate = useNavigate();
  const [showInfoTips, setShowInfoTips] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const list1Ref = useRef(null);
  const list2Ref = useRef(null);
  const list3Ref = useRef(null);

  const wordList = [
    'Space exploration milestones',
    'Globalization effects',
    'Effects of colonial rule',
    'Innovations in e-governance',
    'Water scarcity solutions',
    'Grassroot democratic reforms',
    "Indigenous peoples' rights",
    'Transboundary water conflict',
    'Sustainable agricultural innovations',
    'Enhancing public health systems',
    'International Relations',
    'Ancient Civilizations',
    'Mitigating soil degradation',
    'Revitalizing rural economies',
    'Advancing gender equality',
  ];

  // Splitting the word list into 3 different groups
  const list1Words = wordList.slice(0, 5);
  const list2Words = wordList.slice(5, 10);
  const list3Words = wordList.slice(10, 15);

  useEffect(() => {
    const scrollSpeed1 = 1;
    const scrollSpeed2 = 1;
    const scrollSpeed3 = 1;

    const scrollHorizontally = (list, speed) => {
      if (list) {
        list.scrollLeft += speed;
        if (list.scrollLeft >= list.scrollWidth - list.clientWidth) {
          list.scrollLeft = 0; // Reset to the beginning for infinite scrolling
        }
      }
    };

    const interval1 = setInterval(() => scrollHorizontally(list1Ref.current, scrollSpeed1), 50);
    const interval2 = setInterval(() => scrollHorizontally(list2Ref.current, scrollSpeed2), 50);
    const interval3 = setInterval(() => scrollHorizontally(list3Ref.current, scrollSpeed3), 50);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  const handleWordClick = (word) => {
    // Navigate to the /pyqSearch route and pass the word as a parameter
    navigate('/pyqSearch', { state: { selectedWord: word } });
  };

  // Function to render the word list for each row
  const renderWordItems = (listRef, words) => (
    <Paper
      ref={listRef}
      style={{
        overflowX: 'scroll', // Allow manual scrolling
        display: 'flex',
        whiteSpace: 'nowrap', // Keep words in a single line
        backgroundColor: 'transparent',
        gap: 2,
        flexWrap: 'nowrap', // Prevent wrapping
        width: '100%',
      }}
    >
      {/* Render words and clone them for infinite scrolling */}
      {[...words, ...words].map((item, index) => (
        <div
          key={index}
          style={{
            marginRight: '16px',
            backgroundColor: '#EBF3FF',
            color: '#3C45CD',
            padding: '8px 12px 8px 16px',
            borderRadius: '25px',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            fontWeight: '500',
            fontSize: '12.5px',
            cursor: 'pointer',
            fontFamily: 'Poppins',
          }}
          onClick={() => handleWordClick(item)} // Navigate on click
        >
          {item}
          <img src={SendIcon} size={16} style={{ marginLeft: '8px' }} /> {/* Correct arrow direction */}
        </div>
      ))}
    </Paper>
  );

  const LowerSection = () => (
    <>
      <CustomDivider style={{ margin: '2em 0' }} />
      <Title order={3} style={{ fontSize: '16px', fontWeight: '600', color: '#070519', marginBottom: '20px', fontFamily: 'Poppins' }}>
        Try any of the topics below
      </Title>
      <Group position="apart" noWrap style={{ display: 'flex', gap: 0, flexDirection: 'column', overflowX: 'auto' }}>
        {renderWordItems(list1Ref, list1Words)}
        {renderWordItems(list2Ref, list2Words)}
        {renderWordItems(list3Ref, list3Words)}
      </Group>
    </>
  );

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '&::-webkit-scrollbar': {
          // Hide scrollbar in Chrome/Safari/Opera
          display: 'none',
        },
      }}
    >
      <div
        style={{
          maxWidth: windowDimensions.width <= 768 ? '100vw' : '28rem',
          boxShadow: '0px 19px 38px   rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22)',
          margin: '0 auto',
          paddingTop: '50px',
          textAlign: 'center',
          paddingBottom: '100px',
          height: windowDimensions.width > 1024 ? '100vh' : 'auto', 
        }}
      >
        <Image src={PyqSearchLogo} alt="Header Image" width={200} mx="auto" style={{ marginBottom: '8%' }} />

        {/* Title and Info Icon */}
        <div
          style={{
            marginBottom: '20px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Title
            order={2}
            style={{
              fontSize: '20px',
              fontWeight: '600',
              color: '#070519',
              fontFamily: 'Poppins',
            }}
          >
            Meaning-Based PYQ search
          </Title>
          <img src={InfoCircle} style={{ color: '#6374FA', cursor: 'pointer' }} onClick={() => setShowInfoTips(true)} />
        </div>

        {/* Search Input */}
        <TextInput
          leftSectionPointerEvents="none"
          onClick={() => navigate('/pyqSearch')}
          leftSection={
            <div
              style={{
                backgroundColor: '#6374FA',
                borderRadius: '50%',
                padding: '0.4em',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src={SearchNormal} style={{ width: '100%' }} />
            </div>
          }
          size="lg"
          styles={{
            input: {
              border: '1px solid #6374FA',
              borderRadius: '25px',
              color: '#2C2E3F',
              backgroundColor: '#F7F8FC',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              outline: '8px solid #E1EBFF',
              width: '100%',
              fontFamily: 'Poppins',
            },
          }}
          style={{
            margin: '2em auto',
            width: '90%',
          }}
          placeholder="Search PYQs here..."
        />

        {/* Lower Section */}
        <LowerSection />

        {/* Info Tips Modal */}
        {showInfoTips && (
          <InfoTips
            onClose={() => {
              setShowInfoTips(false);
              MixpanelEvent('screen_closed', {
                source_screen: 'Meaning-Based PYQ Search',
                close_type: 'modal',
              });
              sendPostHogEvent('screen_closed', {
                source_screen: 'Meaning-Based PYQ Search',
                close_type: 'modal',
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AutoScrollLists;
