import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { callGETAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import { Card, Text, Box, Image, Button } from '@mantine/core';
import { useTheme } from '../../context/themeProvider';
import NoHighlights from './NoHighlights';
import { TheHindu, IndianExpress, Edit2, TickCircle } from '../../assets/images';
import Pagination from './pagination'; // Import the Pagination component

export default function MyNotes() {
  const [groupedHighlights, setGroupedHighlights] = useState({});
  const [expandedNews, setExpandedNews] = useState({});
  const [editMode, setEditMode] = useState(null);
  const { colors } = useTheme();
  const userData = userStore((state) => state.userData);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [heightClass, setHeightClass] = useState("");

  const fetchMyNote = (page) => {
    if (userData.user_id) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/highlights/?user=${userData.user_id}&page=${page}&page_size=50`,
        (response) => {
          console.log(response, 'response data');
          if (response.data) {
            const grouped = response.data.results.reduce((acc, item) => {
              if (!acc[item.id]) {
                acc[item.id] = {
                  ...item,
                  highlights: [],
                };
              }
              acc[item.id].highlights.push(...item.highlighted_points);
              return acc;
            }, {});
            setGroupedHighlights(grouped);
            setTotalPages(Math.ceil(response.data.count / 50));
          }
        },
        (error) => {
          console.error('API call error:', error);
        }
      );
    }
  };

  useEffect(() => {
    fetchMyNote(currentPage);
  }, [currentPage, userData?.user_id]);





  const updateHeightClass = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    console.log('Viewport Height:', viewportHeight,viewportWidth); // Log the height

    if (viewportHeight > 1450 && viewportWidth > 1440) {
      setHeightClass('83dvh');
    } else if (viewportHeight > 1300 && viewportWidth > 1440) {
      setHeightClass('77dvh');
    } else if (viewportHeight > 1275 && viewportWidth > 1440) {
      setHeightClass('75dvh');
    } else if (viewportHeight > 1200 && viewportWidth > 1440) {
      setHeightClass('73dvh');
    } else if (viewportHeight > 1000 && viewportWidth > 1440) {
      setHeightClass('70dvh');
    } else if (viewportHeight > 900 && viewportWidth > 1000) {
      setHeightClass('66dvh');
    } else if (viewportHeight > 800 && viewportWidth > 1000) {
      setHeightClass('60dvh');
    } else if (viewportWidth < 400 && viewportHeight < 700) {
      setHeightClass('51dvh');
    } else if (viewportWidth < 480  && viewportHeight < 700) {
      setHeightClass('53dvh');
    }else if (viewportWidth < 400 && viewportHeight < 800) {
      setHeightClass('54dvh');
    }else if (viewportWidth < 400 && viewportHeight < 900) {
      setHeightClass('60dvh');
    }else if (viewportWidth < 480 && viewportHeight < 975) {
      setHeightClass('62dvh');
    }  else {
      setHeightClass('70dvh'); // Default height
    }
  };

  useEffect(() => {
    updateHeightClass(); // Initial call
    window.addEventListener('resize', updateHeightClass); // Update on resize

    return () => {
      window.removeEventListener('resize', updateHeightClass); // Cleanup
    };
  }, []);



  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCardClick = (newsItem) => {
    navigate(`/news/${newsItem.id}`, {
      state: {
        newsItem: {
          id: newsItem.id,
          highlights: newsItem.highlighted_points
        },
      },
    });
  };

  const deleteHighlight = async (newsId, highlightId) => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/highlight/delete/${highlightId}/`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });

      setGroupedHighlights((prevState) => {
        const updatedGrouped = { ...prevState };
        const highlights = updatedGrouped[newsId]?.highlights.filter(
          (highlight) => highlight.id !== highlightId
        );

        if (highlights.length === 0) {
          delete updatedGrouped[newsId];
        } else {
          updatedGrouped[newsId] = {
            ...updatedGrouped[newsId],
            highlights,
          };
        }

        return updatedGrouped;
      });
    } catch (error) {
      console.error('Error deleting highlight:', error);
    }
  };

  const handleDeleteClick = (newsId, highlightId) => {
    deleteHighlight(newsId, highlightId);
  };

  const toggleExpand = (news_id) => {
    setExpandedNews((prevState) => ({
      ...prevState,
      [news_id]: !prevState[news_id],
    }));
  };

  const toggleEditMode = (news_id) => {
    toggleExpand(news_id);
    setEditMode((prevState) => (prevState === news_id ? null : news_id));
  };

  if (Object.keys(groupedHighlights).length === 0) {
    return <NoHighlights />;
  }

  return (
    <>
    <div
      style={{
        backgroundColor: colors.background,
        color: colors.text,
        height: heightClass,
        overflow: 'scroll',
        padding: '10px',
        scrollbarWidth: 'none', 
        msOverflowStyle: 'none', 
      }}
    >
      {Object.values(groupedHighlights)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sorting by date
        .map((newsItem) => (
          <Card
            key={newsItem.id}
            shadow="sm"
            radius="md"
            withBorder
            style={{
              backgroundColor: colors.cardBackground,
              borderColor: colors.borderColor,
              marginBottom: '20px',
              position: 'relative',
              paddingBottom: '60px',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'space-between',
                display: 'flex',
                gap: '30px',
                marginBottom: '10px',
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  textAlign: 'center',
                  gap: '3px',
                  display: 'flex',
                }}
              >
                <Image
                  src={newsItem.news_paper === 'The Hindu' ? TheHindu : newsItem.news_paper === 'The Indian Express' ? IndianExpress : ''}
                  alt="Newspaper Image"
                  style={{
                    height: '12px',
                  }}
                />
                <Text
                  size="sm"
                  weight={500}
                  style={{ marginLeft: '10px', color: '#747474' }}
                >
                  {new Date(newsItem.created_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  })}
                </Text>
              </div>
              <div>
                <img
                  onClick={() => toggleEditMode(newsItem.id)}
                  width="20px"
                  height="20px"
                  src={editMode === newsItem.id ? TickCircle : Edit2} // Conditional rendering based on editMode
                  alt="Edit Icon"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text
                variant="light"
                size="sm"
                style={{ color: 'black', marginTop: '5px', background: '#EBF3FF', flex: 1 }}
                onClick={() => handleCardClick(newsItem)}
              >
                {1}. {newsItem.highlights[0].highlighted_content_text}
              </Text>
              {editMode === newsItem.id && (
                <span
                  className="delete-icon"
                  style={{
                    color: 'red',
                    fontSize: '20px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '10px',
                  }}
                  onClick={() => handleDeleteClick(newsItem.id, newsItem.highlights[0].id)}
                >
                  -
                </span>
              )}
            </div>

            {newsItem.highlights.length > 1 && (
              <Button
                onClick={() => toggleExpand(newsItem.id)}
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '0',
                  width: '100%',
                  color: colors.text,
                  display: 'flex',
                  backgroundColor: 'transparent',
                  justifyContent: 'start',
                }}
              >
                {expandedNews[newsItem.id] ? 'See Less' : `+${newsItem.highlights.length - 1} More Highlights`}
              </Button>
            )}

            {expandedNews[newsItem.id] && (
              <div
                style={{
                  backgroundColor: colors.expandedBackground,
                  borderRadius: '8px',
                  maxHeight: '300px',
                  paddingTop: '10px',
                  overflowY: 'auto',
                  marginTop: '10px',
                }}
              >
                {newsItem.highlights.slice(1).map((highlight, index) => (
                  <Box
                    key={highlight.id}
                    style={{
                      backgroundColor: colors.cardBackground,
                      borderColor: colors.borderColor,
                      borderRadius: '8px',
                      marginBottom: '10px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      variant="light"
                      size="sm"
                      style={{
                        color: 'black',
                        background: '#EBF3FF',
                        flex: 1,
                      }}
                      onClick={() => handleCardClick(newsItem)}
                    >
                      {index + 2}. {highlight.highlighted_content_text}
                    </Text>
                    {editMode === newsItem.id && (
                      <span
                        className="delete-icon"
                        style={{
                          color: 'red',
                          fontSize: '20px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '10px',
                        }}
                        onClick={() => handleDeleteClick(newsItem.id, highlight.id)}
                      >
                        -
                      </span>
                    )}
                  </Box>
                ))}
              </div>
            )}
          </Card>
        ))}
    </div>
    {/* Pagination Section */}
    <div
      style={{
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: colors.cardBackground,
        borderTop: `1px solid ${colors.borderColor}`,
        position:'relative',
        bottom:'0',
        right:'0',
        left:'0',
        zIndex:999,
      }}
    >
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
}
