import { NoBookmarks } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { useTheme } from '../../context/themeProvider';
import { callGETAPI } from '../../utils/NetworkUtil';
import { useEffect, useState } from 'react';
import NewsListItem from './newsCard';
import { useNavigate } from 'react-router-dom';
import Pagination from './pagination'; // Import the Pagination component

export default function Bookmarks() {
  const userData = userStore((state) => state.userData);
  const { colors } = useTheme();
  const [userBookmarkData, setUserBookmarkData] = useState([]);
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const navigate = useNavigate();
  const [heightClass, setHeightClass] = useState('');

  const fetchBookmarks = (page) => {
    if (userData?.user_id) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/user-bookmarks/?user=${userData.user_id}&page=${page}&page_size=50`,
        (response) => {
          console.log(response, 'response');
          if (response.data?.results) {
            setUserBookmarkData(response.data.results);
            setTotalPages(Math.ceil(response.data.count / 50));
          }
        },
        (error) => {
          console.error('API call error:', error);
        }
      );
    }
  };

  useEffect(() => {
    fetchBookmarks(currentPage);
  }, [currentPage, userData?.user_id]);

  // Dynamically set height class based on viewport size
  const updateHeightClass = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    console.log('Viewport Height:', viewportHeight,viewportWidth); // Log the height

    if (viewportHeight > 1450 && viewportWidth > 1440) {
      setHeightClass('83dvh');
    } else if (viewportHeight > 1300 && viewportWidth > 1440) {
      setHeightClass('77dvh');
    } else if (viewportHeight > 1275 && viewportWidth > 1440) {
      setHeightClass('75dvh');
    } else if (viewportHeight > 1200 && viewportWidth > 1440) {
      setHeightClass('73dvh');
    } else if (viewportHeight > 1000 && viewportWidth > 1440) {
      setHeightClass('70dvh');
    } else if (viewportHeight > 900 && viewportWidth > 1000) {
      setHeightClass('66dvh');
    } else if (viewportHeight > 800 && viewportWidth > 1000) {
      setHeightClass('60dvh');
    } else if (viewportWidth < 400 && viewportHeight < 700) {
      setHeightClass('51dvh');
    } else if (viewportWidth < 480  && viewportHeight < 700) {
      setHeightClass('53dvh');
    }else if (viewportWidth < 400 && viewportHeight < 800) {
      setHeightClass('54dvh');
    }else if (viewportWidth < 400 && viewportHeight < 900) {
      setHeightClass('60dvh');
    }else if (viewportWidth < 480 && viewportHeight < 975) {
      setHeightClass('62dvh');
    }  else {
      setHeightClass('70dvh'); // Default height
    }
  };

  useEffect(() => {
    updateHeightClass(); // Initial call
    window.addEventListener('resize', updateHeightClass); // Update on resize

    return () => {
      window.removeEventListener('resize', updateHeightClass); // Cleanup
    };
  }, []);

  const handleNewsClick = (data, index) => {
    navigate(`/news/${data.id}`, {
      state: {
        newsItem: {
          id: data.id,
        },
      },
    });
  };

  if (userBookmarkData.length === 0) {
    return <NoBookmarkComp colors={colors} />;
  }

  return (
    <>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          padding: '10px',
          overflowY: 'scroll',
          zIndex: 1,
          height: heightClass,
          scrollbarWidth: 'none',
        }}
      >
        {userBookmarkData?.map((data, index) => (
          <NewsListItem
            key={data.id}
            tags={data?.gpt_output?.mains_syllabus || []}
            title={data.title}
            likes={data.likes}
            bookmarks={data.bookmarks}
            imageSrc={data.imageSrc}
            newsItem={data}
            newsType={selectedNews}
            created_at={data.created_at}
            onClick={() => handleNewsClick(data, index)}
          />
        ))}
      </div>
    </div>
      <div
      style={{
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: colors.cardBackground,
        borderTop: `1px solid ${colors.borderColor}`,
        position:'relative',
        bottom:'0',
        right:'0',
        left:'0',
        zIndex:999,
      }}
    >
        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
        </div>
    </>
  );
}

const NoBookmarkComp = ({ colors }) => {
  return (
    <div style={{ height: '72vh', backgroundColor: colors.background, overflow: 'scroll' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div style={{ width: '80%', maxWidth: '600px', textAlign: 'center' }}>
          <img src={NoBookmarks} alt="nothing here img" style={{ width: '100%', objectFit: 'cover', marginBottom: '1em' }} />
          <h2
            style={{
              fontWeight: 400,
              fontFamily: 'Poppins, sans-serif',
              marginBottom: '0.5em',
            }}
          >
            Nothing here!
          </h2>
          <p
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '0.9em',
              margin: '0 2em',
              lineHeight: '1.8em',
              color: '#747474',
            }}
          >
            Bookmark your favorite News Summaries for a personalized News Feed.
          </p>
        </div>
      </div>
    </div>
  );
};
