import React from 'react';
import {
  ArrowLeftDarkIcon,
  ArrowRightDarkIcon,
  ArrowLeftFilled,
  ArrowRightFilled,
} from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const { colorScheme } = useTheme();


  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPageButton(i));
      }
    } else {
      if (currentPage === 1) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(
          <span key="ellipsis-end" style={styles.ellipsis}>
            ...
          </span>
        );
        pageNumbers.push(renderPageButton(totalPages));
      } else if (currentPage === totalPages) {
        pageNumbers.push(renderPageButton(1));
        pageNumbers.push(
          <span key="ellipsis-start" style={styles.ellipsis}>
            ...
          </span>
        );
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(renderPageButton(i));
        }
      } else {
        pageNumbers.push(renderPageButton(1));

        if (currentPage > 2) {
          pageNumbers.push(
            <span key="ellipsis-start" style={styles.ellipsis}>
              ...
            </span>
          );
          pageNumbers.push(renderPageButton(currentPage - 1));
        }

        pageNumbers.push(renderPageButton(currentPage));

        if (currentPage < totalPages) {
          pageNumbers.push(
            <span key="ellipsis-end" style={styles.ellipsis}>
              ...
            </span>
          );
        }

        pageNumbers.push(renderPageButton(totalPages));
      }
    }

    return pageNumbers;
  };

  const renderPageButton = (pageNumber) => (
    <button
      key={pageNumber}
      onClick={() => onPageChange(pageNumber)}
      style={{
        ...styles.pageButton,
        ...(currentPage === pageNumber ? styles.activePageButton : {}),
      }}
    >
      {pageNumber}
    </button>
  );

  return (
    <div style={styles.container}>
      <button
        style={{
          ...styles.arrowButton,
          ...(currentPage === 1 ? styles.disabledArrowButton : {}),
        }}
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <img
          src={
            currentPage === 1
              ? colorScheme === 'dark'
                ? ArrowLeftDarkIcon
                : ArrowLeftFilled
              : colorScheme === 'dark'
              ? ArrowLeftDarkIcon
              : ArrowLeftFilled
          }
          alt="Previous"
          style={styles.arrowIcon}
        />
      </button>

      <div style={styles.pageNumbers}>{renderPageNumbers()}</div>

      <button
        style={{
          ...styles.arrowButton,
          ...(currentPage === totalPages ? styles.disabledArrowButton : {}),
        }}
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <img
          src={
            currentPage === totalPages
              ? colorScheme === 'dark'
                ? ArrowRightDarkIcon
                : ArrowRightFilled
              : colorScheme === 'dark'
              ? ArrowRightDarkIcon
              : ArrowRightFilled
          }
          alt="Next"
          style={styles.arrowIcon}
        />
      </button>
    </div>
  );
};
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '16px 0',
    },
    pageNumbers: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 16px',
    },
    pageButton: {
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 4px',
      fontSize: '14px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: 'black',
      cursor: 'pointer',
    },
    activePageButton: {
      backgroundColor: '#6374FA',
      color: 'white',
    },
    arrowButton: {
      width: '40px',
      height: '40px',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #ccc',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    disabledArrowButton: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    ellipsis: {
      padding: '0 8px',
      fontWeight: 'bold',
    },
    arrowIcon: {
      width: '20px',
      height: '20px',
    },
  };

export default Pagination;
